export default {
  phoneAlert: "Enter your phone, please",
  emailAlert: "Enter correct email address, please",
  priceFrom: "From {price}{currency}",
  download: "Download file",
  hour: "hour",
  min: "min",
  bookingControl: "Booking Management",
  cabinet: "Personal Account",
  heroTitle: "Discover New Aspects of Travel",
  heroSubTitle: "Find and book unforgettable experiences.",

  ticketPurchase: "Ticket Purchase",
  bookingControl: "Booking Management",
  transfer: "Transfer",
  hotels: "Hotels",

  informationLabels: {
    vacancies: "Vacancies",
  },
  flightWeekdays: {
    0: "Every Sunday from {date}",
    1: "Every Monday from {date}",
    2: "Every Tuesday from {date}",
    3: "Every Wednesday from {date}",
    4: "Every Thursday from {date}",
    5: "Every Friday from {date}",
    6: "Every Saturday from {date}",
  },
  flightDuration: "{h} hr. {m} min.",

  vacancy: "Vacancies",
  contacts: "Contacts",
  services: "Services",

  buyTicket: "Buy a Ticket",
  excessLuggage: "Excess Luggage",
  orderFood: "Order Food",
  placeChoose: "Seat Selection",
  registration: "Registration",
  information: "Information",
  tariffs: "Tariffs",
  shippingRules: "Shipping Rules",
  luggageShipping: "Luggage Shipping",
  passengerCategory: "Passenger Categories",
  aq: "Questions and Answers",
  news: "News",
  company: "Company",
  pathline: "Route Network",
  ourFleet: "Our Fleet",
  confPolicy: "Privacy Policy",

  yourLogin: "Your Login",
  yourPassword: "Your Password",
  resetPassword: "Change Password",
  login: "Login",

  from: "From",
  to: "To",
  there: "One Way",
  back: "Return",
  when: "When",
  delete: "Delete",
  addRoute: "Add Route",
  setComplexRoute: "Create a Complex Route",
  simpleRoute: "Simple Route",
  thereAndBack: "Round Trip",
  ticketSearch: "Search Tickets",

  serviceRequestForm: "Service Request Form",
  name: "Name",
  surname: "Surname",
  ticketInfo: "Ticket Number/Booking Number",
  flightDate: "Flight Date",
  flightNumber: "Flight Number",
  leaveApplication: "Submit Request",

  ticketInfo2: "Booking Code/Airline Ticket Number",
  surnameLatin: "Surname in Latin Letters",

  passengerAndClass: "Passenger and Class",
  passengerCount: "Number of Passengers",
  adults: "Adults",
  age12plus: "Over 12 years old at the time of flight",
  children: "Children",
  ageTo12: "From 2 to 12 years old at the time of flight",
  infants: "Infants",
  ageTo2: "Up to 2 years (without a seat, on an adult's lap)",
  ageTo2Seat: "Up to 2 years (with seat)",
  serviceClass: "Service Class",
  done: "Done",
  ekonomClass: "Economy Class",
  businessClass: "Business Class",

  payment: "Payment",

  flyWithUs: "Fly With Us",
  flyToAdventures: "Fly to Unforgettable Adventures",
  seeAll: "See All",

  beAwareOfNews: "Stay Updated with the Latest News",
  allNews: "All Airline News",
  popularFLights: "Popular Flights",
  goToAdventures: "Go Towards Adventures",

  additionalServices: "Additional Services",

  time: "Time",
  bySchedule: "By Schedule",
  buy: "Buy",
  chooseYourDestination: "Choose Your Convenient Destination",
  find: "Find",

  detailed: "More Details",
  readMore: "Read Also",
  aboutTitle: "Kupibilet - Your Smart Travel Assistant",
  inDigits: "We in Numbers",
  easyWayBuyTicket: "An Easy Way to Buy a Ticket",

  additionalServiceSubtitle: "Find and book unforgettable experiences.",
  order: "Order",

  faq: "Frequently Asked Questions",
  sendResume: "Submit Resume",
  name: "Name",
  enterYourName: "Enter Your Name",
  phoneNumber: "Phone Number",
  enterPhone: "Enter Your Phone Number",
  enterEmail: "Enter Your Email",
  addCV: "Attach Resume",
  send: "Submit",

  status_0: "Departed on schedule",
  status_1: "Canceled",
  status_2: "Delayed",
  status_3: "Departed",
  status_4: "Diverted to alternate",
  status_5: "Landed",
  status_6: "Data pending",
  status_7: "Landed at alternate",
  status_8: "Departed from alternate",
  flightStatus: "Flight status",
  register: "Register",
  goToOnlineTable: "Go To online-table",
  onlineRegister: "Online Registration",
  bookingCode: "Booking Code/Ticket Number",
  symbols6To3: "6 to 13 characters",
  secondNameLatin: "Last Name, in Latin letters",
  onlineTable: "Online Table",
  enterCity: "Start entering the city",

  departure: "Departure",
  arrival: "Arrival",
  noFlightsFound: "No flights found",
  nextFlights: "Next flights",
  departureDate: "Departure date",
  noFlight: "Flight not found",
  passenger: "passenger",
  departureArrivalCity: "Departure or Arrival city",

  aboutUs: "About Us",
  selectDate: "Select a date!",
  planeModel: "Plane model",
  planeCount: "Number of planes",
  seatPlan: "Seat plan",
  ourJournals: "Our journal",
};
