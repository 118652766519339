export default {
  phoneAlert: "Введите ваш номер телефона",
  emailAlert: "Проверьте адрес электронной почты",
  priceFrom: "От {price}{currency}",
  download: "Скачать файл",
  hour: "час.",
  min: "мин.",
  bookingControl: "Управление бронированием",
  cabinet: "Личный Кабинет",
  heroTitle: "Откройте для себя новые аспекты путешествий",
  heroSubTitle: "Найдите и забронируйте незабываемые впечатления.",

  ticketPurchase: "Покупка билетов",
  bookingControl: "Управление Бронированием",
  transfer: "Трансфер",
  hotels: "Отели",

  informationLabels: {
    vacancies: "Вакансии",
  },
  flightWeekdays: {
    0: "Каждое Воскресенье с {date}",
    1: "Каждый Понедельник с {date}",
    2: "Каждый Вторник с {date}",
    3: "Каждую Среду с {date}",
    4: "Каждый Четверг с {date}",
    5: "Каждую Пятницу с {date}",
    6: "Каждую Субботу с {date}",
  },
  flightDuration: "{h} чаc. {m} мин.",

  vacancy: "Вакансии",
  contacts: "Контакты",
  services: "Услуги",

  buyTicket: "Купить авиабилет",
  excessLuggage: "Сверхнормативный багаж",
  orderFood: "Заказать питание",
  placeChoose: "Выбор места",
  registration: "Регистрация",
  information: "Информация",
  tariffs: "Тарифы",
  shippingRules: "Правила перевозок",
  luggageShipping: "Перевозка багажа",
  passengerCategory: "Категории пассажиров",
  aq: "Вопросы и ответы",
  news: "Новости",
  company: "Компания",
  pathline: "Маршрутная сеть",
  ourFleet: "Наш Флот",
  confPolicy: "Политика конфиденциальности",

  yourLogin: "Ваш логин",
  yourPassword: "Ваш пароль",
  resetPassword: "Изменить пароль",
  login: "Войти",

  from: "Откуда",
  to: "Куда",
  there: "Туда",
  back: "Обратно",
  when: "Когда",
  delete: "Удалить",
  addRoute: "Добавить маршрут",
  setComplexRoute: "Составить сложный маршрут",
  simpleRoute: "Обычный маршрут",
  thereAndBack: "Туда и обратно",
  ticketSearch: "Поиск билетов",

  serviceRequestForm: "Форма заявки для услуг",
  name: "Имя",
  surname: "Фамилия",
  ticketInfo: "Номер билета/Номер бронирования",
  flightDate: "Дата полета",
  flightNumber: "Номер рейса",
  leaveApplication: "Оставить заявку",

  ticketInfo2: "Код бронирования/номер авиабилета",
  surnameLatin: "Фамилия, латинскими буквами",

  passengerAndClass: "Пассажир и класс",
  passengerCount: "Количество пассажиров",
  adults: "Взрослые",
  age12plus: "Старше 12 лет на момент перелета",
  children: "Дети",
  ageTo12: "От 2 до 12 лет на момент перелета",
  infants: "Младенцы",
  ageTo2: "До 2 лет (без места, на руках у взрослого)",
  ageTo2Seat: "До 2 лет (с местом)",
  serviceClass: "Класс обслуживания",
  done: "Готово",
  ekonomClass: "Эконом класс",
  businessClass: "Бизнес класс",

  payment: "Оплата",

  flyWithUs: "Летайте вместе с нами",
  flyToAdventures: "Полетели на встречу к незабываемым приключениям",
  seeAll: "Посмотреть все",

  beAwareOfNews: "Будьте в курсе последних новостей",
  allNews: "Все новости авиокомпании",
  popularFLights: "Популярные рейсы",
  goToAdventures: "Пошли на встречу приключениям",

  additionalServices: "Дополнительные услуги",

  time: "Время",
  bySchedule: "По расписанию",
  buy: "Купить",
  chooseYourDestination: "Выбирайте удобное для вас направление",
  find: "Найти",

  detailed: "Подробнее",
  readMore: "Читайте также",
  aboutTitle: "Купибилет ваш умный помощник в путешествиях",
  inDigits: "Мы в цифрах",
  easyWayBuyTicket: "Простой способ купить билет",

  additionalServiceSubtitle: "Найдите и забронируйте незабываемые впечатления.",
  order: "Заказать",

  faq: "Часто задаваемые вопросы",
  sendResume: "Отправить резюме",
  name: "Имя",
  enterYourName: "Введите ваше имя",
  phoneNumber: "Номер телефона",
  enterPhone: "Введите ваш номер",
  enterEmail: "Введите ваш Email",
  addCV: "Прикрепить резюме",
  send: "Отправить",

  status_0: "Вылет по расписанию",
  status_1: "Отменен",
  status_2: "Задержан",
  status_3: "Вылетел",
  status_4: "Ушел на запасной",
  status_5: "Совершил посадку",
  status_6: "Данные ожидаются",
  status_7: "Сел на запасной",
  status_8: "Взлетел с запасного",
  flightStatus: "Статус рейса",

  register: "Зарегистрироваться",
  goToOnlineTable: "Перейти в раздел Онлайн-табло",

  onlineRegister: "Онлайн-регистрация",
  bookingCode: "Код бронирования/Номер билета",
  symbols6To3: "от 6 до 13 символов",
  secondNameLatin: "Фамилия, латинскими буквами",
  onlineTable: "Онлайн-табло",
  enterCity: "Начните вводить город",

  departure: "Вылет",
  arrival: "Прилет",
  noFlightsFound: "Рейсы не найдены",
  nextFlights: "Ближайшие рейсы",
  departureDate: "Дата вылета",
  noFlight: "Рейс не найден",
  passenger: "пассажир",
  departureArrivalCity: "Город вылета или прилета",

  aboutUs: "О нас",
  selectDate: "Выберите дату!",
  planeModel: "Модель самолёта",
  planeCount: "Количество самолётов",
  planeModel: "Модель самолёта",
  seatPlan: "Схемы посадочных мест",

  ourJournals: "Наш журнал",
};
