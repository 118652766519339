export default {
  phoneAlert: "Iltimos, telefon raqamini tugri kiriting",
  emailAlert: "Iltimos, elektron pochtani tugri kiriting",
  priceFrom: "{price}{currency} dan",
  download: "Yuklab olish",
  hour: "soat",
  min: "daq.",
  bookingControl: "Buyurtmani boshqarish",
  cabinet: "Shaxsiy Kabinet",
  heroTitle: "Sayohatning yangi jihatlarini kashf eting",
  heroSubTitle: "Unutilmas tajribalarni toping va bron qiling.",

  ticketPurchase: "Chipta sotib olish",
  bookingControl: "Buyurtmani boshqarish",
  transfer: "Transfer",
  hotels: "Mehmonxonalar",

  informationLabels: {
    vacancies: "Bo'sh ish joylari",
  },
  flightWeekdays: {
    0: "Har yakshanba {date} dan boshlab",
    1: "Har dushanba {date} dan boshlab",
    2: "Har seshanba {date} dan boshlab",
    3: "Har chorshanba {date} dan boshlab",
    4: "Har payshanba {date} dan boshlab",
    5: "Har juma {date} dan boshlab",
    6: "Har shanba {date} dan boshlab",
  },
  flightDuration: "{h} soat. {m} daq.",

  vacancy: "Vakansiyalar",
  contacts: "Kontaktlar",
  services: "Xizmatlar",

  buyTicket: "Chipta sotib olish",
  excessLuggage: "Qo‘shimcha Yuk",
  orderFood: "Taom Buyurtma Qilish",
  placeChoose: "O‘rindiq Tanlash",
  registration: "Ro‘yxatdan O‘tish",
  information: "Ma'lumot",
  tariffs: "Tariflar",
  shippingRules: "Yuk Tashish Qoidalari",
  luggageShipping: "Yukni Tashish",
  passengerCategory: "Yo‘lovchilar toifasi",
  aq: "Savollar va Javoblar",
  news: "Yangiliklar",
  company: "Kompaniya",
  pathline: "Marshrut Tarmog‘i",
  ourFleet: "Bizning Parkimiz",
  confPolicy: "Maxfiylik Siyosati",

  yourLogin: "Loginingiz",
  yourPassword: "Parolingiz",
  resetPassword: "Parolni O‘zgartirish",
  login: "Kirish",

  from: "Qayerdan",
  to: "Qayerga",
  there: "Ketish",
  back: "Qaytish",
  when: "Qachon",
  delete: "O‘chirish",
  addRoute: "Marshrut Qo‘shish",
  setComplexRoute: "Murakkab marshrut yaratish",
  simpleRoute: "Oddiy Marshrut",
  thereAndBack: "Ketish va Qaytish",
  ticketSearch: "Chipta olish",

  serviceRequestForm: "Xizmat So‘rovi Formasi",
  name: "Ism",
  surname: "Familiya",
  ticketInfo: "Chipta Raqami/Bronlash Raqami",
  flightDate: "Parvoz Sanasi",
  flightNumber: "Reys Raqami",
  leaveApplication: "So'rovnoma qoldirish",

  ticketInfo2: "Bron Kod/Chipta Raqami",
  surnameLatin: "Familiya (lotin harflari bilan)",

  passengerAndClass: "Yo‘lovchilar va xizmat turi",
  passengerCount: "Yo‘lovchilar Soni",
  adults: "Kattalar",
  age12plus: "12 yoshdan katta",
  children: "Bolalar",
  ageTo12: "2 dan 12 yoshgacha",
  infants: "Chaqaloqlar",
  ageTo2: "2 yoshgacha(o'rindiqsiz)",
  ageTo2: "2 yoshgacha(o'rindiq bilan)",
  serviceClass: "Xizmat Turi",
  done: "Tayyor",
  ekonomClass: "Tejamkor",
  businessClass: "Biznes",

  payment: "To‘lov",

  flyWithUs: "Biz bilan birga parvoz qiling",
  flyToAdventures: "Unutilmas sarguzashtlar tomon uching",
  seeAll: "Hammasi bilan tanishish",

  beAwareOfNews: "So'nggi yangiliklardan xabardor bo'ling",
  allNews: "Barcha Yangiliklar",
  popularFLights: "Ommabop parvozlar",
  goToAdventures: "Sarguzashtlarga Yo‘l Oling",

  additionalServices: "Qo‘shimcha Xizmatlar",

  time: "Vaqt",
  bySchedule: "Jadval Bo‘yicha",
  buy: "Sotib Olish",
  chooseYourDestination: "Qulay Yo‘nalishni Tanlang",
  find: "Topish",

  detailed: "Batafsil",
  readMore: "Shuningdek O‘qing",
  aboutTitle: "Kupibilet - Sizning Aql Usta Sayohat Yordamchingiz",
  inDigits: "Bizning Raqamlarimiz",
  easyWayBuyTicket: "Chipta sotib olishning Oson Yo‘li",

  additionalServiceSubtitle: "Unutilmas tajribalarni toping va bron qiling.",
  order: "Buyurtma berish",

  faq: "Ko‘p Beriladigan Savollar",
  sendResume: "Rezyume Yuboring",
  name: "Ism",
  enterYourName: "Ismingizni Kiriting",
  phoneNumber: "Telefon Raqami",
  enterPhone: "Telefon Raqamingizni Kiriting",
  enterEmail: "Email Manzilingizni Kiriting",
  addCV: "Rezyume Qo‘shish",
  send: "Yuborish",

  status_0: "Jadval bo‘yicha uchib ketdi",
  status_1: "Bekor qilindi",
  status_2: "Kechikdi",
  status_3: "Uchib ketdi",
  status_4: "Zaxira aeroportiga ketdi",
  status_5: "Qo‘ndi",
  status_6: "Ma'lumotlar kutilmoqda",
  status_7: "Zaxira aeroportida qo‘ndi",
  status_8: "Zaxira aeroportidan uchib ketdi",
  flightStatus: "Parvoz holati",
  register: "Ro'yhatdan o'tish",
  goToOnlineTable: "Onlayn jadvalga o'tish",

  onlineRegister: "Onlayn ro'yxatdan o'tish",
  bookingCode: "Bron kodi/Bilet raqami",
  symbols6To3: "6 dan 13 gacha belgilar",
  secondNameLatin: "Familiya, lotin harflari bilan",
  onlineTable: "Onlayn jadval",
  enterCity: "Shaharni tanlang",

  departure: "Borish",
  arrival: "Qaytish",
  noFlightsFound: "Reyslar topilmadi",
  nextFlights: "Eng yaqin reyslar",
  departureDate: "Jo'nash sanasi",
  noFlight: "Reys topilmadi",
  passenger: "yo'lovchi",
  departureArrivalCity: "Jo'nash yoki kelish shahri",

  aboutUs: "Biz haqimizda",
  selectDate: "Sanani tanlang!",
  planeModel: "Samolyot modeli",
  planeCount: "Samolyotlar soni",
  seatPlan: "O'rindiqlar sxemasi",
  ourJournals: "Bizning jurnal",
};
